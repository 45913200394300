<template>
  <component v-bind:is="layout"></component>
  <!-- <app-to-top-button></app-to-top-button> -->
</template>

<script type="ts">
import { defineComponent, computed, provide, readonly, ref } from 'vue'
import { useStore } from '@/store/index'
import MainLayout from '@/views/layouts/Main'
import WelcomLayout from '@/views/layouts/Welcome'
import AppHeader from '@/components/AppHeader'
import AppToTopButton from '@/components/controls/AppToTopButton'
import { provideWishFilter } from '@/composables/wishFilter'

export default defineComponent({
  name: 'App',
  components: { AppHeader, AppToTopButton },
  setup() {
    const store = useStore()

    // TODO inject/provideにできるかどうか
    const user = computed(() => store.state.user)

    const layout = computed(() => {
      // ログイン済みかどうかでメインレイアウトを変更します。
      return user.value ? MainLayout : WelcomLayout;
      // return path.value === '/' ? WelcomLayout  : MainLayout
    })


    // provide('user', readonly(user))
    // provide('user', user)
    provide('user', user)
    provideWishFilter()

    return {
      layout: layout,
    }
  },
})
</script>
