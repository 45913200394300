<template>
  <app-header @login="login" @logout="logout"></app-header>
  <router-view />
</template>

<script type="ts">
import { defineComponent, computed, provide  } from 'vue'
import FireBase from '@/firebase'
import { useStore } from '@/store/index'
import { useRouter } from 'vue-router'

import AppHeader from '@/components/AppHeader'

export default defineComponent({
  name: 'Welcom',
  components: { AppHeader },
  setup() {
    // TODO ログイン処理などはMain.vueと共通にしたい
    const store = useStore()
    const router = useRouter()

    const user = computed(() => store.state.user)
    provide('user', user)

    const login = async () => {
      await FireBase.loginAsync()
      .then((result) => {
        if (result.uid) {
          router.push({ name: 'Rooms'})
        }
      })
    }

    const logout = () => {
      FireBase.logout()
      router.push({ name: 'Home'})
    }

    return {
      user,
      login,
      logout
    }
  },
})
</script>
