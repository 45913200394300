import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   component: () =>
  //     import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  // },
  {
    path: '/rooms',
    name: 'Rooms',
    component: () =>
      import(/* webpackChunkName: "rooms" */ '../views/RoomList.vue'),
  },
  {
    path: '/rooms/add',
    name: 'RoomAdd',
    component: () =>
      import(/* webpackChunkName: "roomAdd" */ '../views/RoomAdd.vue'),
  },
  {
    path: '/rooms/:id/invite',
    name: 'RoomInvite',
    component: () =>
      import(/* webpackChunkName: "roomInvite" */ '../views/RoomInvite.vue'),
  },
  {
    path: '/rooms/invited',
    name: 'RoomInvited',
    component: () =>
      import(/* webpackChunkName: "roomInvited" */ '../views/RoomInvited.vue'),
  },
  {
    path: '/rooms/:id/wishes',
    name: 'WishListShow',
    component: () =>
      import(/* webpackChunkName: "wishList" */ '../views/WishList.vue'),
  },
  {
    path: '/rooms/:id/wishes/add',
    name: 'WishAdd',
    component: () =>
      import(/* webpackChunkName: "wishAdd" */ '../views/WishAdd.vue'),
  },
  {
    path: '/rooms/:roomId/wishes/:wishId/edit',
    name: 'WishEdit',
    component: () =>
      import(/* webpackChunkName: "wishEdit" */ '../views/WishEdit.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // Bulmaのactiveクラスへ変更
  linkActiveClass: 'is-active',
})

// GOOD
// router.beforeEach((to, from, next) => {
//   next()
// })

export default router
