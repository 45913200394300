<template>
  <!-- <aside class="menu aside hero is-fullheight is-hidden-mobile p-5"> -->
  <aside class="menu aside hero">
    <p class="menu-label">General</p>
    <ul class="menu-list">
      <li>
        <router-link to="/rooms">Rooms</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'RoomInvited' }">Invited Room</router-link>
      </li>
    </ul>
    <!-- <p class="menu-label">Administration</p>
    <ul class="menu-list">
      <li><a>Account Settings</a></li>
    </ul> -->
  </aside>
</template>
