import { InjectionKey } from 'vue'
import { createStore, Store, useStore as baseUseStore } from 'vuex'
import { User } from '@/types/user'

export interface State {
  user: User | null
}

export const key: InjectionKey<Store<State>> = Symbol()

export const store = createStore({
  state: {
    // ログインユーザ
    user: null as User | null,
  },
  getters: {
    user(state) {
      return state.user
    },
  },
  mutations: {
    onAuthUserChanged(state, user) {
      state.user = user
    },
  },
  actions: {},
  modules: {},
})

export function useStore(): Store<State> {
  return baseUseStore(key)
}
