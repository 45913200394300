<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        <strong>Wishlist</strong> by
        <a href="https://kawadev.net">kawadev.net</a>.
      </p>
    </div>
  </footer>
</template>
