import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import '@/store/index'
// import store from '@/store/index'
import { store } from '@/store/index'
import { User } from '@/types/user'

const config = {
  apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
  authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.VUE_APP_FIREBASE_APP_ID,
  measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
}

const provider = new firebase.auth.GoogleAuthProvider()

/*
class FireBaseApp {
  constructor() {
    firebase.initializeApp(config)
    firebase.analytics()
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    // ブラウザとじてもセッション維持(明示的なログアウトが必要)
    // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  }
  /*
  init(): void {
    firebase.initializeApp(config)
    firebase.analytics()
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    // ブラウザとじてもセッション維持(明示的なログアウトが必要)
    // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  }

  login(): void {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const credential = result.credential as firebase.auth.OAuthCredential
        // GoogleプロパイダのOAuthトークンを取得します。
        if (result.credential) {
          const token = credential.accessToken
          // ログインしたユーザーの情報を取得します。
          const user = result.user
          console.log(token, user)

          console.log(firebase.auth().currentUser)
        }
      })
      .catch(function (err) {
        console.error(err)
        // エラー処理
      })
  }
  auth() {
    return firebase.auth()
  }
  user(): firebase.User | null {
    return firebase.auth().currentUser
  }

  onAuthStateChanged(callable: any): void {
    firebase.auth().onAuthStateChanged((user) => callable(user))
    // firebase.auth().onAuthStateChanged((user) => {
    //   if (user) {
    //     // User is signed in, see docs for a list of available properties
    //     // https://firebase.google.com/docs/reference/js/firebase.User
    //     var uid = user.uid;
    //     this.user = user
    //     console.log(user)
    //     // ...
    //   } else {
    //     // User is signed out
    //     // ...
    //   }
    // })
  }
}
*/
// export default FireBase
// export const FireBase = new FireBaseApp()

export default {
  init: (): void => {
    firebase.initializeApp(config)
    firebase.analytics()
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
    // ブラウザとじてもセッション維持(明示的なログアウトが必要)
    // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  },

  login: (): void => {
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        const credential = result.credential as firebase.auth.OAuthCredential
        // GoogleプロパイダのOAuthトークンを取得します。
        if (result.credential) {
          const token = credential.accessToken
          // ログインしたユーザーの情報を取得します。
          const user = result.user
          console.log(token, user)
        }
      })
      .catch(function (err) {
        console.error(err)
        // エラー処理
      })
  },
  loginAsync: async (): Promise<firebase.User | null> => {
    const result = await firebase.auth().signInWithPopup(provider)

    if (result.credential) {
      const credential = result.credential as firebase.auth.OAuthCredential
      const token = credential.accessToken
      // ログインしたユーザーの情報を取得します。
      const user = result.user

      console.log(token, user)
      return user
    }
    return null
    // .then((result) => {
    //   const credential = result.credential as firebase.auth.OAuthCredential
    //   // GoogleプロパイダのOAuthトークンを取得します。
    //   if (result.credential) {
    //     const token = credential.accessToken
    //     // ログインしたユーザーの情報を取得します。
    //     const user = result.user
    //     console.log(token, user)
    //   }
    // })
    // .catch(function (err) {
    //   console.error(err)
    //   // エラー処理
    // })
  },

  logout: (): void => {
    firebase.auth().signOut()
  },

  onAuth: (): void => {
    firebase.auth().onAuthStateChanged((user) => {
      console.log('fiebase onauth changed', user)
      // TODO email取得できなければエラー投げる
      if (user && user.email) {
        user.uid
        const fireBaseUser: Readonly<User> = {
          uid: user.uid,
          email: user.email,
          name: user.displayName,
        }
        store.commit('onAuthUserChanged', fireBaseUser)
        // console.info(store)
      } else {
        store.commit('onAuthUserChanged', null)
      }
    })
  },
}
