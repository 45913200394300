<template>
  <button
    id="to-top"
    @click="onClick"
    class="button is-rounded is-link is-medium"
    title="Go to top"
  >
    <span class="icon">
      <i class="fas fa-angle-up"></i>
    </span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const onClick = () => {
      document.querySelector('body')?.scrollIntoView({ behavior: 'smooth' })
    }

    return {
      onClick,
    }
  },
})
</script>

<style lang="scss" scoped>
#to-top {
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border-radius: 50%;
  box-shadow: 0 5px 20px rgba(14, 25, 80, 0.3);
  &:hover {
    background-color: #43485c;
  }
}
</style>
