import { inject, provide, reactive } from 'vue'

const key = Symbol()

export type State = {
  showBought: boolean
}

export type WishFilter = {
  filterState: State
}

export const createWishFilter = (): WishFilter => {
  const filterState: State = reactive({
    showBought: true,
  })

  return {
    filterState,
  }
}

// provide.
export const provideWishFilter = (): void => {
  provide(key, createWishFilter())
}

// inject.
export const useWishFilter = (): WishFilter => {
  return inject(key) as WishFilter
}
