
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const onClick = () => {
      document.querySelector('body')?.scrollIntoView({ behavior: 'smooth' })
    }

    return {
      onClick,
    }
  },
})
