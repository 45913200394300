<template>
  <section class="hero">
    <div class="hero-body">
      <div class="columns">
        <div class="column is-10 is-offset-1">
          <p class="title">Welcom Wishlist!</p>
          <p class="subtitle">Wishlist subtitle</p>
        </div>
      </div>
    </div>
  </section>
  <!-- <div class="home">
    <img alt="Vue logo" src="../assets/logo.png" />
    <HelloWorld msg="Welcome to Your Vue.js + TypeScript App" />
  </div> -->
</template>

<script lang="ts">
import { defineComponent } from 'vue'
// import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

export default defineComponent({
  name: 'Home',
  // components: {
  //   HelloWorld,
  // },
})
</script>
