
import { defineComponent, inject } from 'vue'
import FireBase from '@/firebase'
// import { User } from '@/types/user'
import { useRouter } from 'vue-router'

import AppHeader from '@/components/AppHeader.vue'
import AppSideMenu from '@/components/AppSideMenu.vue'
import AppFooter from '@/components/AppFooter.vue'

export default defineComponent({
  name: 'Main',
  components: { AppHeader, AppSideMenu, AppFooter },
  setup() {
    const router = useRouter()

    // const user: User | null = inject('user', null)

    const login = async () => {
      await FireBase.loginAsync().then((result) => {
        if (result && result.uid) {
          router.push({ name: 'Rooms' })
        }
      })
    }

    const logout = () => {
      FireBase.logout()
      router.push({ name: 'Home' })
    }

    // onMounted(() => {
    //   console.log(process.env)
    // })

    return {
      // user: user,
      login: login,
      logout: logout,
    }
  },
})
