<template>
  <nav class="navbar">
    <div class="container">
      <div class="navbar-brand">
        <router-link to="/" class="navbar-item">
          <img src="https://bulma.io/images/bulma-logo.png" alt="Logo" />
        </router-link>
        <div class="navbar-item is-mobile">
          {{ user?.name }}
        </div>
        <span
          class="navbar-burger burger"
          @click="toggleNavbarMenu"
          :class="navbarMenuActive ? 'is-active' : null"
        >
          <span></span>
          <span></span>
          <span></span>
        </span>
      </div>
      <div class="navbar-menu" :class="navbarMenuActive ? 'is-active' : null">
        <div class="navbar-end">
          <!-- <div class="navbar-item">
            <div class="control has-icons-left">
              <input
                class="input is-rounded"
                type="search"
                placeholder="Search"
              />
              <span class="icon is-left">
                <i class="fa fa-search"></i>
              </span>
            </div>
          </div>
          <router-link
            to="/"
            class="navbar-item is-size-5 has-text-weight-semibold"
            >Home</router-link
          > -->
          <div class="is-hidden-tablet">
            <hr class="navbar-hb-divider" />
            <router-link
              :to="{ name: 'Rooms' }"
              @click="toggleNavbarMenu"
              class="navbar-item is-size-5 has-text-weight-semibold"
            >
              Rooms
            </router-link>

            <router-link
              @click="toggleNavbarMenu"
              :to="{ name: 'RoomInvited' }"
              class="navbar-item is-size-5 has-text-weight-semibold"
            >
              Invited Room
            </router-link>
          </div>
          <!-- <router-link
            to="/about"
            class="navbar-item is-size-5 has-text-weight-semibold"
            >About</router-link
          > -->
          <!-- <router-link
            to="/rooms"
            class="navbar-item is-size-5 has-text-weight-semibold"
            >Rooms</router-link
          > -->
          <a
            v-show="!user"
            @click="login"
            id="login"
            class="navbar-item is-size-5 has-text-weight-semibold"
            >ログイン</a
          >

          <a
            v-show="user"
            @click="logout"
            class="navbar-item is-size-5 has-text-weight-semibold"
            >ログアウト</a
          >
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent, inject, ref } from 'vue'
import { User } from '@/types/user'

export default defineComponent({
  name: 'AppHeader',
  setup(_, context) {
    const login = () => context.emit('login')
    const logout = () => context.emit('logout')
    // const user: User | null = inject('user', null)
    const user = inject<User>('user')
    let navbarMenuActive = ref(false)

    const toggleNavbarMenu = () => {
      navbarMenuActive.value = !navbarMenuActive.value
    }

    return {
      user,
      login,
      logout,
      toggleNavbarMenu,
      navbarMenuActive,
    }
  },
})
</script>

<style lang="scss" scoped>
.navbar-hb-divider {
  background-color: whitesmoke;
  border: none;
  // display: none;
  height: 2px;
  margin: 0.5rem 0;
}
</style>
