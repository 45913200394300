import { createApp, ComponentPublicInstance } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { store, key } from './store'
import FireBase from './firebase'
import firebase from 'firebase/app'
import { User } from '@/types/user'

require('@/sass/main.scss')

// FireBase初期化と認証状態監視
FireBase.init()
// FireBase.onAuth()

let app: ComponentPublicInstance
firebase.auth().onAuthStateChanged((user) => {
  // console.log('onauthstatechanged by main.ts')
  // TODO emailが取得できなければ error投げる
  if (user && user.email) {
    const fireBaseUser: Readonly<User> = {
      uid: user.uid,
      email: user.email,
      name: user.displayName,
    }
    // TODO inject/provideにできるかどうか
    store.commit('onAuthUserChanged', fireBaseUser)
  } else {
    store.commit('onAuthUserChanged', null)
  }

  // console.log('call createAPP')
  if (!app) {
    // Appインスタンス生成をFireBaseの認証処理を待つ
    app = createApp(App).use(store, key).use(router).mount('#app')
  }
})
