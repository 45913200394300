
import { defineComponent, inject, ref } from 'vue'
import { User } from '@/types/user'

export default defineComponent({
  name: 'AppHeader',
  setup(_, context) {
    const login = () => context.emit('login')
    const logout = () => context.emit('logout')
    // const user: User | null = inject('user', null)
    const user = inject<User>('user')
    let navbarMenuActive = ref(false)

    const toggleNavbarMenu = () => {
      navbarMenuActive.value = !navbarMenuActive.value
    }

    return {
      user,
      login,
      logout,
      toggleNavbarMenu,
      navbarMenuActive,
    }
  },
})
